<template>
    <div class="upload-box">
        <i-header :title="config['title'] ? config['title'].template : ''" @back-event="toBack"></i-header>
        <div id="testmap" style="display: none"></div>
        <div class="cnt-box" style="position: relative">
            <i-tips :tipsList="tipsList"></i-tips>
            <!-- <div v-html="config['commonTemplate'].tips ? config['commonTemplate'].tips:''"></div> -->
            <div class="mustGeoLocation" v-if="ismustGeoLocation">
                <iFinderFrame v-for="(file, index) in optionsList" :key="index" :mygeoLocation="imygeoLocation"
                              :ismustGeoLocation="ismustGeoLocation" @fileBtnCallBack="fileBtnCallBack"
                              :uploaderSettings="file"></iFinderFrame>
            </div>
            <template v-else>
                <iFinderFrame v-for="(file, index) in optionsList" :key="index" :tempLocation="imygeoLocation"
                              :uploaderSettings="file" @fileBtnCallBack="fileBtnCallBack"></iFinderFrame>
            </template>
        </div>
    </div>
</template>

<script>
import iFinderFrame from "@/components/iFinderFrame";
import generic from "../../utils/generic";

export default {
    name: "index",
    inject: ['reload'],
    components: {
        iFinderFrame,
    },
    destroyed() {
        while (document.querySelector(".viewer-container")) {
            document.querySelector(".viewer-container").remove();
        }
    },

    data() {
        return {
            imygeoLocation: {},
            title: "履行文件",
            tipsList: [],
            onlyRead: false,
            optionsList: [],
            directoryId: this.$cookie.get("tenant_id") || this.$smartStorage.get("tenant_id"),
            ismustGeoLocation: false,
            config: {},
            miceInfo: this.$smartStorage.get("miceInfo")
        };
    },
    mounted() {
        this.isOnlyRead();
        this.getConfig();
        this.getTips();
        // window.getLocationCallback = this.getLocationCallback;
    },
    methods: {
        getConfig() {
            generic.getConfigs({
                    miceId: this.$route.query.miceId,
                    group: this.$route.query.type || "Files",
                    router: this.$route.path
                })
                .then((config) => {
                    this.config = config
                    for (const configKey in config) {
                        if (config[configKey].callback) this[config[configKey].callback]()
                    }
                });
        },
        async init() {
            let _config = generic.replaceEvalObject(this.config['fileTemplate'], this.$smartStorage.get("miceInfo"))
            let res = await this.$service.GenericPost(_config);
            if (res.success && res.content) {
                if (res.content.needGPS) {
                    this.toastInstance = this.$itoast.loading({duration: 0, message: "正在获取GPS", forbidClick: true});
                    //需要配合原生发包打开
                    // if (window.flutter_inappwebview) {
                    //     this.getFlutterLocationOnce()
                    // }
                    if (this.iJsBridge) {
                        this.hasLocationPermission();
                    } else {
                        this.amapLocation();
                    }
                    this.ismustGeoLocation = true;
                }
                let tmpArr = [];
                res.content.uploadOptions.map((item) => {
                    item.filterData = {
                        catalog: item.catalog,
                        processId: this.$route.query.miceId,
                    };
                    if (item.replaceEvalPath) {
                        let cacheItem = generic.replaceEvalObject(this.jsonCopy(item), this.$smartStorage.get('miceInfo'));
                        let visibleRuleExpr = cacheItem.visibleRuleExpr;
                        // console.log('visibleRuleExpr', visibleRuleExpr)
                        if (visibleRuleExpr !== false) tmpArr.push(cacheItem);
                    } else {
                        tmpArr.push(item);
                    }
                });
                this.optionsList = tmpArr;

            }
        },
        toBack() {
            this.$router.back();
        },
        getFlutterLocationOnce() {
            window.flutter_inappwebview.callHandler("Amap.getLocationOnce", 'getLocationCallback');
        },
        async getTips() {
            let params = {
                collection: "cfg-crossevent-noticebar",
                useCaching: false,
                cachingKey: "",
                filter: {
                    TenantCode: "crossevent",
                    DirectoryId: this.directoryId,
                    EntryCode: "ievent-add-performance-file",
                },
                projection: {
                    _id: 0,
                },
            };
            let res = await this.$service.GetSettings(params);
            if (res && res.success && res.content) {
                this.tipsList = res.content.content;
            }
        },
        isOnlyRead() {
            let miceStatus = this.$smartStorage.get("miceInfo")?.Status || 0;
            let onlyReadStatusList = [-1, 20, 80, 100];
            if (onlyReadStatusList.includes(miceStatus)) {
                this.onlyRead = true;
            }
        },
        handleProcessAct() {
            this.$itoast.fail('请检查"网络是否正常"或者"移动设备的定位是否开启且允许(会引擎APP/企业微信)获取位置信息"');
            if (this.iJsBridge) {
                this.hasLocationPermission();
            } else {
                this.amapLocation();
            }
        },
        // 检查APP是否开启定位
        hasLocationPermission() {
            this.iJsBridge.call({
                method: "SMPermission.hasLocationPermission",
                postData: {},
                callback: this.hasLocationPermissionCallback,
            });
        },
        hasLocationPermissionCallback(data) {
            if (data === true || data === "true") {
                this.getLocation();
            } else {
                this.$itoast.fail('请检查"网络是否正常"或者"移动设备的定位是否开启且允许(会引擎APP/企业微信)获取位置信息"');
            }
        },
        // 原生定位
        getLocation() {
            this.iJsBridge.call({
                method: "SMAmap.getLocation",
                postData: {},
                callback: this.getLocationCallback,
            });
        },
        getLocationCallback(addressInfo) {
            console.log("原生定位回调：", addressInfo);
            if (typeof addressInfo === "string") {
                addressInfo = JSON.parse(addressInfo);
            }
            // 若原生定位失败（定位失败返回的经纬度都是0）
            if (addressInfo.lat == 0 || addressInfo.lon == 0 || addressInfo.latitude || addressInfo.longitude) {
                this.amapLocation();
            } else {
                this.imygeoLocation = {
                    lat: addressInfo.lat || addressInfo.latitude,
                    lng: addressInfo.lon || addressInfo.longitude,
                };
                this.toastInstance.close();
            }
        },
        amapLocation() {
            // 使用高德地图获取位置信息
            AMap.plugin("AMap.Geolocation", () => {
                var geolocation = new AMap.Geolocation({
                    enableHighAccuracy: true, //是否使用高精度定位，默认:true
                    timeout: 5000, //超过10秒后停止定位，默认：5s
                    position: "RB", //定位按钮的停靠位置
                    offset: [10, 20], //定位按钮与设置的停靠位置的偏移量，默认：[10, 20]
                    zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
                });
                geolocation.getCurrentPosition((status, georesult) => {
                    console.log("====高德地图获取到的位置信息", status, JSON.stringify(georesult))
                    if (status == "complete") {
                        AMap.plugin("AMap.Geocoder", () => {
                            var geocoder = new AMap.Geocoder();
                            var lnglat = [georesult.position.lng, georesult.position.lat];
                            geocoder.getAddress(lnglat, (status, result) => {
                                console.log("====高德地图获取到的城市信息", status, JSON.stringify(result));
                                if (status === "complete" && result.info === "OK") {
                                    // result为对应的地理位置详细信息
                                    let auditData = {
                                        province: result.regeocode.addressComponent.province,
                                        city: result.regeocode.addressComponent.city,
                                        address: result.regeocode.formattedAddress
                                    };
                                    this.imygeoLocation = {
                                        lat: georesult.position.lat,
                                        lng: georesult.position.lng,
                                        auditData,
                                    };
                                } else {
                                    this.imygeoLocation = {
                                        lat: georesult.position.lat,
                                        lng: georesult.position.lng,
                                        auditData: {},
                                    };
                                }
                                this.toastInstance.clear()
                            });
                        });
                    } else {
                        this.toastInstance.clear()
                        this.$itoast('未获取到地址信息')
                        console.log(status, JSON.stringify(georesult))
                    }
                });
            });
        },
        fileBtnCallBack(btnItem) {
            let _btnItem = generic.replaceEvalObject(btnItem, this.$smartStorage.get('miceInfo') || {})
            this.$service.GenericPost(_btnItem).then((res) => {
                if (res && res.success) {
                    let _this = this
                    if (_btnItem.successCallBackExpr) {
                        try {
                            return eval(_btnItem.successCallBackExpr)
                        } catch (e) {
                            console.log("文件按钮expr执行错误：", e)
                        }
                    }
                }
            })
        }
    },
};
</script>

<style lang="less" scoped>
.upload-box {
    width: 100vw;
    height: 100%;
    background: #ffffff;

    .cnt-box {
        height: calc(100% - 0.44rem);
        padding: 0 0.25rem 0.34rem 0.25rem;
        overflow-y: scroll;
        box-sizing: border-box;

        .item-box {
            margin-top: 0.2rem;

            .title {
                text-align: left;
                font-size: 0.16rem;
                font-weight: 500;
                color: #000000;
            }

            .tips {
                margin-left: -17%;
                font-size: 0.12rem;
                color: rgb(201, 14, 14);
                transform: scale(0.7);
                -ms-transform: scale(0.7); /* IE 9 */
                -webkit-transform: scale(0.7); /* Safari and Chrome */
            }

            .img-box {
                margin-top: 0.05rem;

                .item {
                    width: 1rem;
                    height: 1rem;
                    background: #f8f8f8;
                    border-radius: 0.13rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .upload-icon {
                        width: 0.3rem !important;
                        height: 0.3rem !important;
                        display: block;
                    }
                }
            }
        }
    }
}
</style>
