var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-box" },
    [
      _c("i-header", {
        attrs: {
          title: _vm.config["title"] ? _vm.config["title"].template : "",
        },
        on: { "back-event": _vm.toBack },
      }),
      _c("div", { staticStyle: { display: "none" }, attrs: { id: "testmap" } }),
      _c(
        "div",
        { staticClass: "cnt-box", staticStyle: { position: "relative" } },
        [
          _c("i-tips", { attrs: { tipsList: _vm.tipsList } }),
          _vm.ismustGeoLocation
            ? _c(
                "div",
                { staticClass: "mustGeoLocation" },
                _vm._l(_vm.optionsList, function (file, index) {
                  return _c("iFinderFrame", {
                    key: index,
                    attrs: {
                      mygeoLocation: _vm.imygeoLocation,
                      ismustGeoLocation: _vm.ismustGeoLocation,
                      uploaderSettings: file,
                    },
                    on: { fileBtnCallBack: _vm.fileBtnCallBack },
                  })
                }),
                1
              )
            : _vm._l(_vm.optionsList, function (file, index) {
                return _c("iFinderFrame", {
                  key: index,
                  attrs: {
                    tempLocation: _vm.imygeoLocation,
                    uploaderSettings: file,
                  },
                  on: { fileBtnCallBack: _vm.fileBtnCallBack },
                })
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }